/* eslint-disable react/style-prop-object */

/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const ChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
            <mask id='mask0_63_2591' width={24} height={24} x={0} y={0} maskUnits='userSpaceOnUse' style={{
      maskType: "alpha"
    }}>
                <path fill='currentColor' d='M0 0h24v24H0V0Z' />
            </mask>
            <g mask='url(#a)'>
                <path fill='currentColor' d='M6.4 10a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l3.9 3.9 3.9-3.9a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L12.4 16c-.1.1-.208.171-.325.213a1.115 1.115 0 0 1-.375.062c-.133 0-.258-.02-.375-.062A.883.883 0 0 1 11 16l-4.6-4.6a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7Z' />
            </g>
        </svg>;
};

export default ChevronDown;